::-webkit-scrollbar {
  width: 0.5vw;
}
::-webkit-scrollbar-track {
  width: 0.5vw;
}
::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 8vw;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #A0AEC0;
  border-radius: 8vw;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Editor {
  border: none;
}

